import * as types from '../constants/actionTypes'

const initialState = {
  parameters: []
}

const Global = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PARAMETERS_GLOBAL:
      return {...state, parameters: action.payload}
    default:
      return state
  }
}

export default Global