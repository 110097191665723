import axios from 'axios'
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}
const ajaxUrl = 'AjaxHelper.aspx';

export const GetAllVerboseData = (value) => {
    let formData = new FormData();
    formData.append('function', 'GetDataVerbose');
    return axios.post(process.env.REACT_APP_BASE_URL + ajaxUrl, formData, config);
}

export const GetWaitingRoom = (rsid, crematoriumId, lg) => {
    let formData = new FormData();
    formData.append('function', 'GetWaitingRoom');
    formData.append('rsid', rsid);
    formData.append('crematoriumId', crematoriumId);
    formData.append('lg', lg);
    return axios.post(process.env.REACT_APP_BASE_URL + ajaxUrl, formData, config);
}

export const GetCeremonyRoom = (rsid, crematoriumId) => {
    let formData = new FormData();
    formData.append('function', 'GetCeremonyRoom');
    formData.append('rsid', rsid);
    formData.append('crematoriumId', crematoriumId);
    return axios.post(process.env.REACT_APP_BASE_URL + ajaxUrl, formData, config);
}

export const GetPlanning = (crematoriumId, lg) => {
    let formData = new FormData();
    formData.append('function', 'GetPlanning');
    formData.append('crematoriumId', crematoriumId);
    formData.append('lg', lg);
    return axios.post(process.env.REACT_APP_BASE_URL + ajaxUrl, formData, config);
}

export const GetUpcomming = (crematoriumId, lg) => {
    let formData = new FormData();
    formData.append('function', 'GetUpcomming');
    formData.append('crematoriumId', crematoriumId);
    formData.append('lg', lg);
    return axios.post(process.env.REACT_APP_BASE_URL + ajaxUrl, formData, config);
}

export const ChooseReservation = (rid, rsid) => {
    let formData = new FormData();
    formData.append('function', 'ChooseReservation');
    formData.append('rid', rid);
    formData.append('rsid', rsid);
    return axios.post(process.env.REACT_APP_BASE_URL + ajaxUrl, formData, config);
}
export const ValidatePasscode = (passcode) => {
    let formData = new FormData();
    formData.append('function', 'ValidatePasscode');
    formData.append('passcode', passcode);
    return axios.post(process.env.REACT_APP_BASE_URL + ajaxUrl, formData, config);
}

export const GetParametersGlobal = (crematoriumId, lg) => {
    let formData = new FormData();
    formData.append('function', 'GetParametersGlobal');
    formData.append('crematoriumId', crematoriumId);
    formData.append('lg', lg);
    return axios.post(process.env.REACT_APP_BASE_URL + ajaxUrl, formData, config);
}
