import React, {Suspense, useEffect} from 'react'
import { HashRouter } from 'react-router-dom'
import { routes } from './routes/routes'
import { useDispatch } from 'react-redux'
import { getParametersGlobal } from './actions/ReservationAction'

function App() {
  const baseUrl = '/'
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getParametersGlobal(window.currentCrematoriumId,window.currentLanguage))
  }, []);

  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <HashRouter children={routes} basename={baseUrl} />
      </Suspense>
    </React.Fragment>
  );
}

export default App;
