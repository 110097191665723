import * as types from '../constants/actionTypes'

import {
  ChooseReservation, GetParametersGlobal
} from '../services'

export const chooseReservation = (rid, rsid, callback) => dispatch => {
  dispatch({
    type: types.LOADING_DATA,
    isLoading: true
  });

  ChooseReservation(rid, rsid).then(n => {
    dispatch({
      type: types.CHOOSE_RESERVATION,
      payload: n.data
    })
    if (callback)
      callback()
  })
}

export const getParametersGlobal = (crematoriumId, lg, callback) => dispatch => {
  GetParametersGlobal(crematoriumId, lg).then(n => {
    dispatch({
      type: types.GET_PARAMETERS_GLOBAL,
      payload: n.data
    })
    if (callback)
      callback()
  })
}