import {combineReducers}  from  'redux';
import Funeral from './Funeral'
import Planning from './Planning'
import Upcoming from './Upcoming'
import CeremonyRoom from './CeremonyRoom'
import Global from './Global'

const reducer = combineReducers({
  Funeral,
  Planning,
  Upcoming,
  CeremonyRoom,
  Global
})

export default reducer;